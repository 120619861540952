/** @jsx jsx */
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { Box, jsx, Image, Grid } from "theme-ui";
import React, { useCallback } from "react";
import LayoutHome from "../layout/LayoutHome";
import GroupImage from "../../static/imgs/Kenya-Group-donation.png";
import HeartImage from "../../static/imgs/Kenya-heart-donation.png";
import ChildImage from "../../static/imgs/Kenya-child-donation.png";
import { eventCallbackTriggers } from "../templates/wppage";

const KESButtonConfig =
  '<gatsby_donation amount="0" btntext="Contribute Now - KES" donationid="31" ' +
  'projectid="" btnbg="#922529" colortext="black" colorprimary="#922529"' +
  'colorsecondary="#922529" colorbackground="white" colormuted="#f6f6f6"' +
  'colorhighlight="#efeffe" titletext="Heartfulness Kenya Donation"/>';

const USDButtonConfig =
  '<gatsby_donation amount="0" btntext="Contribute Now - USD" donationid="31" ' +
  'projectid="" btnbg="#922529" colortext="black" colorprimary="#922529"' +
  'colorsecondary="#922529" colorbackground="white" colormuted="#f6f6f6"' +
  'colorhighlight="#efeffe" titletext="Heartfulness Kenya Donation" currency="USD"/>';

const PageContent = React.memo(PageContentNonMemoized);
const KenyaDonation = () => {
  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("heartfulness-kenya-donation", event),
    []
  );

  return (
    <LayoutHome
      seoTitle="Donations - Heartfulness Donations for Kenya"
      contentHeader={<h3>Heartfulness Kenya Donations</h3>}
      contentHeaderStyle="contentHeaderText"
    >
      <Grid
        columns={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Box sx={{ marginTop: "29px" }}>
          <p>
            Heartfulness Institute is a global non-profit organization with
            thousands of volunteer trainers who serve millions of meditators in
            over 130 countries. Its mission is to contribute to the development
            and evolution of a peaceful and holistically balanced society, with
            a spirit of human well-being and oneness.
          </p>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
        >
          <div>
            <PageContent
              eventCallback={eventCallback}
              pageContent={KESButtonConfig}
            />
          </div>
          <div>
            <PageContent
              eventCallback={eventCallback}
              pageContent={USDButtonConfig}
            />
          </div>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Image
              src={GroupImage}
              sx={{
                display: "flex",
                justifyContent: "center!important",
                height: "370px",
                width: "800px",
              }}
            />
          </Box>
        </Box>
        <Box>
          <p>
            Specifically, within Africa, we have our network of Heartfulness
            trainers and volunteers across most parts of the continent who offer
            training in meditation and rejuvenation techniques to individuals
            and institutions, free of charge. In 2016/2017, the Heartfulness
            Institute had trained thousands of youth of Kenya in Heartfulness
            meditation and relaxation techniques. This was possible due to an
            engagement with the local NGO, CAP-YEI that works for the youth from
            disadvantaged sections of society.
          </p>
        </Box>
        <Box>
          <Grid columns={[1, 1, 2, null]}>
            <Box>
              <p>
                This model is also throwing light on what possible models of
                engagement may work in the context of Africa that is a rich
                ground for humanitarian and developmental work. While efforts
                are in progress in this area, there is dire need to address the
                inner need for peace, harmony and integration through simple to
                use and scalable tools and interventions in the current context.
                Similarly, Heartfulness activities have picked up momentum in
                other parts of Africa through the networking of NGOs,
                Ministries, local community groups, etc. In Madagascar, the
                ministry of education has integrated Heartfulness relaxation and
                meditation into the school curriculum. Changes in the children
                like improved attention span, academic performance and emotional
                intelligence are noticed by the teachers and trainers alike
              </p>
            </Box>
            <Box>
              <Image
                src={HeartImage}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "350px",
                }}
              />
            </Box>
          </Grid>
        </Box>
        <Box>
          <p>
            In 2019 an NGO was formed in Kenya (named Heartfulness Institute
            Kenya, NGO), with the objectives to · Further the expansion of
            Heartfulness, both as a way of life and a journey towards oneness; ·
            Promote heart-based behaviours, lives, communities and societies; ·
            Initiate broad-based synergies between groups and institutions
            sharing common vision and values of love, peace and harmony;
          </p>
        </Box>
        <Box>
          <Grid columns={[1, 1, 2, null]}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Image
                src={ChildImage}
                sx={{
                  height: "250px",
                  width: "75%",
                }}
              />
            </Box>
            <Box>
              <p>
                This objective resonates with African aspirations for 2063 as
                articulated in African Union`s Agenda 2063: `The aspirations
                reflect our desire for shared prosperity and well-being, for
                unity and integration, for a continent of free citizens and
                expanded horizons, where the full potential of women and youth,
                boys and girls are realized, and with freedom from fear, disease
                and want.`
              </p>
            </Box>
          </Grid>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
        >
          <div>
            <PageContent
              eventCallback={eventCallback}
              pageContent={KESButtonConfig}
            />
          </div>
          <div>
            <PageContent
              eventCallback={eventCallback}
              pageContent={USDButtonConfig}
            />
          </div>
        </Box>

        <Box>
          <p>
            To date, a resource centre has been established in Nairobi and paid
            staff are actively building capacities to deliver on our objectives.
          </p>
          <p>
            We request the public to join hands with our NGO and contribute
            financially in order to expedite our goals
          </p>
          <p>Thanking you in anticipation </p>
          <p>Heartfulness Institute Kenya, NGO</p>
        </Box>
      </Grid>
    </LayoutHome>
  );
};

export default KenyaDonation;
